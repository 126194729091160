.cc-window.cc-banner {
  .cc-btn {
    color: $global-font-color;

    &:hover, &:focus {
      background-color: #ccc;
    }

    [theme=dark] & {
      color: $global-font-color;

      &:hover, &:focus {
        background-color: #fff;
      }
    }
  }
}
